import {type SurveyType} from 'posthog-js'
import {ctx} from 'new/ctx'
import {
  type AnalyticsEventArgs,
  type AnalyticsEventName,
} from 'types/AnalyticsEventName'
import {PosthogService} from './PosthogService'

////////////////////////////////////////////////////////////////////////////////

export class AnalyticsEventService {
  #posthogService = new PosthogService()
  #userUuid?: string

  constructor() {
    ctx.analyticsEventService = this
  }

  emit<T extends AnalyticsEventName>(
    eventName: T,
    ...args: AnalyticsEventArgs[T]
  ) {
    this.#posthogService.emit(eventName, ...args)
    return this
  }

  optIn() {
    this.#posthogService.optIn()
  }

  optOut() {
    this.#posthogService.optOut()
  }

  fetchPosthogSurvey(surveyType: SurveyType, surveyId: string) {
    return this.#posthogService.posthogSurveys?.find(
      survey => survey.type === surveyType && survey.id === surveyId
    )
  }

  identifyUser(userUuid?: string) {
    this.#userUuid = userUuid
    return this
  }

  addUserProperties(properties: Record<string, any>) {
    if (!this.#userUuid) {
      return this
    }
    this.#posthogService.addUserProperties(this.#userUuid, properties)
    return this
  }
}
