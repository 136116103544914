import React from 'react'
import {CheckCircleFilled} from '@ant-design/icons'
import {notification as AntNotification} from 'antd'
import cn from 'classnames'
import {cloneDeep} from 'lodash-es'
import {CloseIcon} from 'components/Icons'
import styles from './index.module.scss'

///////////////////////////////////////////////////////////////////////////////

type AntNotificationArgs = Parameters<typeof AntNotification.open>[0]
export type NotificationArgs = Partial<AntNotificationArgs>

///////////////////////////////////////////////////////////////////////////////

const open = (
  args: NotificationArgs,
  callback = (options: AntNotificationArgs) => AntNotification.open(options)
) => {
  const options: AntNotificationArgs = {
    message: undefined,
    placement: 'topRight',
    style: {top: 40},
    duration: args.duration ?? (args.description ? 9 : 3),
    closeIcon: <CloseIcon />,
    ...cloneDeep(args),
  }

  let classes = [styles.cont, args.className]

  if (!options.description) {
    classes = [...classes, styles.noDescription]
  }

  if (options.type) {
    const classType = styles[options.type]

    if (classType) {
      classes = [...classes, classType]
    }
  }

  options.className = cn(classes)

  callback(options)
}

///////////////////////////////////////////////////////////////////////////////

const success = (
  args: NotificationArgs,
  callback = (options: AntNotificationArgs) => AntNotification.open(options)
) => {
  open(
    {
      ...args,
      type: 'success',
      icon: <CheckCircleFilled style={{color: 'var(--success-color)'}} />,
    },
    callback
  )
}

const error = (
  args: NotificationArgs,
  callback = (options: AntNotificationArgs) => AntNotification.open(options)
) => {
  open({...args, type: 'error'}, callback)
}

const warning = (
  args: NotificationArgs,
  callback = (options: AntNotificationArgs) => AntNotification.open(options)
) => {
  open({...args, type: 'warning'}, callback)
}

const info = (
  args: NotificationArgs,
  callback = (options: AntNotificationArgs) => AntNotification.open(options)
) => {
  open({...args, type: 'info'}, callback)
}

const useNotification: typeof AntNotification.useNotification = () => {
  const [antApi, contextHolder] = AntNotification.useNotification()

  const api: typeof antApi = {
    ...antApi,
    success: (args: NotificationArgs) =>
      success(args, options => antApi.success(options)),
    error: (args: NotificationArgs) =>
      error(args, options => antApi.error(options)),
    warning: (args: NotificationArgs) =>
      warning(args, options => antApi.warning(options)),
    info: (args: NotificationArgs) =>
      info(args, options => antApi.info(options)),
    open: (args: NotificationArgs) =>
      open(args, options => antApi.open(options)),
  }

  return [api, contextHolder]
}

///////////////////////////////////////////////////////////////////////////////

const notification = {open, success, error, warning, info, useNotification}

export default notification
