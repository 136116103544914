import {makeAutoObservable} from 'mobx'
import {posthog, type Survey} from 'posthog-js'
import {AppMode} from 'types/AppMode'
import {type PosthogFeatureFlag} from 'types/FeatureFlag'

////////////////////////////////////////////////////////////////////////////////

export class PosthogService {
  posthogSurveys?: Survey[]

  #posthogApiKey =
    import.meta.env.MODE === AppMode.PROD
      ? import.meta.env.VITE_POSTHOG_API_KEY
      : (window.POSTHOG_API_KEY ?? import.meta.env.VITE_POSTHOG_API_KEY)

  #posthogApiHost =
    import.meta.env.MODE === AppMode.PROD
      ? import.meta.env.VITE_POSTHOG_API_HOST
      : (window.POSTHOG_API_HOST ?? import.meta.env.VITE_POSTHOG_API_HOST)

  #posthogEnabled =
    window.POSTHOG_ENABLED ?? import.meta.env.VITE_POSTHOG_ENABLED

  #sessionRecordingEnabled = import.meta.env
    .VITE_POSTHOG_SESSION_RECORDING_ENABLED

  constructor() {
    makeAutoObservable(this)
    if (this.#posthogApiHost && this.#posthogApiKey && this.#posthogEnabled) {
      posthog.init(this.#posthogApiKey, {
        api_host: this.#posthogApiHost,
        autocapture: false,
        disable_cookie: true,
        disable_session_recording: !this.#sessionRecordingEnabled,
        persistence: 'memory',
      })
    }
  }

  addUserProperties(userUuid: string, properties: Record<string, any>) {
    if (!this.#posthogEnabled) {
      return
    }
    posthog.identify(userUuid, properties)
  }

  emit(event: string, properties?: Record<string, unknown>) {
    if (!this.#posthogEnabled) {
      return
    }
    posthog.capture(event, properties)
  }

  optIn() {
    if (!this.#posthogEnabled) {
      return
    }

    posthog.opt_in_capturing()
    // User is opted out -> Logs in -> Opts in -> Events are not getting tracked
    // This workaround fixes the issue.
    // https://github.com/PostHog/posthog-js/issues/336
    posthog._start_queue_if_opted_in()
    posthog.startSessionRecording()
    posthog.getActiveMatchingSurveys(surveys => (this.posthogSurveys = surveys))
  }

  optOut() {
    if (!this.#posthogEnabled) {
      return
    }

    posthog.opt_out_capturing()
    posthog.stopSessionRecording()
  }

  // TODO: Move into a separate service
  isFeatureEnabled(
    feature: PosthogFeatureFlag,
    options?: Parameters<typeof posthog.isFeatureEnabled>[1]
  ) {
    if (!this.#posthogEnabled) {
      return false
    }

    return posthog.isFeatureEnabled(feature, options)
  }
}
