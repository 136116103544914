import {inject} from 'react-ioc'
import {comparer, makeAutoObservable, reaction} from 'mobx'
import {toGenerator} from 'lib/helpers/generators'
import {ctx} from 'new/ctx'
import {AnalyticsEventService} from 'new/services/analytics/AnalyticsEventService'
import {BugsnagService} from 'new/services/analytics/BugsnagService'
import {OrganisationsApiService} from 'new/services/OrganisationsApiService'
import {ApprovalStatus, type Organisation} from 'types/Organisation'
import {AppConfigStore} from './AppConfigStore'
import {CurrentUserStore} from './CurrentUserStore'
import {RouterStore} from './RouterStore'
import {SessionStore} from './SessionStore'

///////////////////////////////////////////////////////////////////////////////

export class CurrentOrganisationStore {
  #routerStore = inject<RouterStore>(this, RouterStore)
  #appConfigStore = inject<AppConfigStore>(this, AppConfigStore)
  #sessionStore = inject<SessionStore>(this, SessionStore)
  #organisationsApiService = inject<OrganisationsApiService>(
    this,
    OrganisationsApiService
  )
  #analyticsEventService = inject(this, AnalyticsEventService)
  #bugsnagService = inject<BugsnagService>(this, BugsnagService)
  #currentUserStore = inject<CurrentUserStore>(this, CurrentUserStore)
  #disposers: (() => void)[] = []

  // state
  data?: Organisation

  // fetching flags
  isFetching = false
  fetchingError?: Error

  // sending flags
  isSending = false
  sendingError?: Error

  constructor() {
    ctx.currentOrganisationStore = this
    makeAutoObservable(this)
    this.#initEffects()
  }

  *fetch(organisationUuid: string) {
    try {
      this.isFetching = true
      this.fetchingError = undefined
      this.data = yield* toGenerator(
        this.#organisationsApiService.fetch(organisationUuid)
      )
      // prettier-ignore
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      this.#analyticsEventService.addUserProperties({ organisation_uuid: this.data?.uuid, organisation_approval_status: this.data?.approval_status })
    } catch (error: any) {
      this.#bugsnagService.notifyError(error)
      this.fetchingError = error
    } finally {
      this.isFetching = false
    }
  }

  replace(data?: Organisation) {
    this.data = data
  }

  *closeAccount(organisationUuid: string) {
    const {auth_base_url, connect_base_url} = this.#appConfigStore.data

    try {
      this.isSending = true
      this.sendingError = undefined
      yield this.#organisationsApiService.closeAccount(organisationUuid)

      this.#sessionStore.clearLocalStorage()
      this.#sessionStore.clearCookies()

      const logoutUrl = new URL(`${auth_base_url}/logout`)
      logoutUrl.searchParams.set(
        'target-url',
        `${connect_base_url}/account_closed`
      )
      window.location.href = logoutUrl.href
    } catch (error: any) {
      this.#bugsnagService.notifyError(error)
      this.sendingError = error
    } finally {
      this.isSending = false
    }
  }

  get organisationUuid() {
    return this.data?.uuid
  }

  get organisationApprovalStatus() {
    return this.data?.approval_status
  }

  get isOrgApprovalPending() {
    return this.data?.approval_status === ApprovalStatus.APPROVAL_PENDING
  }

  get isOrgApproved() {
    return this.data?.approval_status === ApprovalStatus.APPROVED
  }

  get accountUuid() {
    return this.data?.accounts[0]?.uuid
  }

  #initEffects() {
    // (Re-)fetch when "user.organisation_uuid" is changed (after user is loaded)
    this.#disposers.push(
      reaction(
        () => this.#currentUserStore.data?.organisation_uuid,
        organisationUuid => {
          if (organisationUuid) {
            this.fetch(organisationUuid)
          }
        },
        {
          fireImmediately: true,
        }
      )
    )

    // Re-fetch when URL is changed if organisation status is "approval_pending"
    // and if it's not currently fetching
    this.#disposers.push(
      reaction(
        () => this.#routerStore.location,
        () => {
          if (
            this.organisationUuid &&
            this.organisationApprovalStatus ===
              ApprovalStatus.APPROVAL_PENDING &&
            !this.isFetching
          ) {
            this.fetch(this.organisationUuid)
          }
        },
        {
          fireImmediately: false,
          equals: comparer.structural,
        }
      )
    )
  }

  dispose() {
    this.#disposers.forEach(fn => fn())
  }
}
