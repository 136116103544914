// Follow this naming pattern: https://posthog.com/product-engineers/5-ways-to-improve-analytics-data

import {type ExportFormat} from 'components/Table/Export'
import {type ApprovalStatus} from './Organisation'
import {type PaymentProviderName} from './PaymentProvider'
import {type Solution, type SolutionType} from './Solution'
import {type SolutionsServiceStatus} from './SolutionsService'

export enum AnalyticsEventName {
  PAGEVIEW = '$pageview',

  EXPORT_BUTTON_CLICK = 'export:button_click',
  EXPORT_SUCCESSFUL = 'export:successful',
  EXPORT_ERROR = 'export:error',
  EXPORT_EXCEEDS_LIMIT = 'export:exceeds_limit',
  EXPORT_MODAL_OPEN = 'export:modal_open',
  EXPORT_MODAL_CANCEL = 'export:modal_cancel',

  LETTERSHOP_VOUCHER_APPLIED = 'lettershop:voucher_applied',
  LETTERSHOP_ORDER_SUCCESSFUL = 'lettershop:order_successful',

  SOLUTION_CREATE_NEW_BUTTON_CLICK = 'solution:create_new_button_click',
  SOLUTION_TYPE_SELECT_CLICK = 'solution:type_select_click',
  SOLUTION_PAYLINK_COPY_BUTTON_CLICK = 'solution:paylink_copy_button_click',
  SOLUTION_CREATED = 'solution:created',
  SOLUTION_UPDATED = 'solution:edited',
  SOLUTION_TWINT_TERMS_ACCEPTED = 'solution:twint_terms_accepted',
  SOLUTION_PAYMENT_PROVIDER_MODAL_CLICK = 'solution:connect_payment_provider_modal_click',
  SOLUTION_UNSAVED_CHANGES_SAVED = 'solution:unsaved_changes_saved',

  SOLUTION_PROMOTE_TWINT_BUTTON_OPEN = 'solution_promote:twint_button_open',
  SOLUTION_PROMOTE_TWINT_WEBSITE_BUTTON_COPY = 'solution_promote:twint_website_button_copy',
  SOLUTION_PROMOTE_TWINT_EMAIL_BUTTON_COPY = 'solution_promote:twint_email_button_copy',

  SOLUTION_PROMOTE_GENERIC_PAYLINK_BUTTON_OPEN = 'solution_promote:generic_paylink_button_open',
  SOLUTION_PROMOTE_GENERIC_PAYLINK_WEBSITE_BUTTON_COPY = 'solution_promote:generic_paylink_website_button_copy',
  SOLUTION_PROMOTE_GENERIC_PAYLINK_EMAIL_BUTTON_COPY = 'solution_promote:generic_paylink_email_button_copy',

  SOLTUION_PROMOTE_SCHEME_PARAMETER_1_COPY = 'solution_promote:scheme_parameter_1_copy',
  SOLTUION_PROMOTE_SCHEME_PARAMETER_2_COPY = 'solution_promote:scheme_parameter_2_copy',
  SOLUTION_PROMOTE_PAYLINK_COPY_BUTTON_CLICK = 'solution_promote:paylink_copy_button_click',

  SOLUTION_PROMOTE_TERMINAL_LINK_COPY_BUTTON_CLICK = 'solution_promote:terminal_link_copy_button_click',

  SOLUTION_PROMOTE_DOWNLOAD_TWINT_QR_BUTTON_CLICK = 'solution_promote:download_twint_qr_button_click',
  SOLUTION_PROMOTE_DOWNLOAD_TWINT_QR_SUCCESS = 'solution_promote:download_twint_qr_success',
  SOLUTION_PROMOTE_DOWNLOAD_TWINT_QR_ERROR = 'solution_promote:download_twint_qr_error',
  SOLUTION_PROMOTE_DOWNLOAD_UNIVERSAL_QR_BUTTON_CLICK = 'solution_promote:download_universal_qr_button_click',
  SOLUTION_PROMOTE_DOWNLOAD_UNIVERSAL_QR_SUCCESS = 'solution_promote:download_universal_qr_success',
  SOLUTION_PROMOTE_DOWNLOAD_UNIVERSAL_QR_ERROR = 'solution_promote:download_universal_qr_error',

  SOLUTION_PROMOTE_DOWNLOAD_TWINT_ACCEPTANCE_SIGNAGE_BUTTON_CLICK = 'solution_promote:download_twint_acceptance_signage_button_click',
  SOLUTION_PROMOTE_DOWNLOAD_TWINT_ACCEPTANCE_SIGNAGE_SUCCESS = 'solution_promote:download_twint_acceptance_signage_success',
  SOLUTION_PROMOTE_DOWNLOAD_TWINT_ACCEPTANCE_SIGNAGE_ERROR = 'solution_promote:download_twint_acceptance_signage_error',

  SIGN_UP_ORGANISATION_CONTINUE_BUTTON_CLICK = 'sign_up:organisation_continue_button_click',
  SIGN_UP_FORM_SUBMIT = 'sign_up:form_submit',

  SIGN_IN_SUCCESSFUL = 'sign_in:successful',

  TAX_RECEIPT_SETTINGS_CUSTOM_DONATION_RECEIPT_SAVED = 'tax_receipt_settings:custom_donation_receipt:saved',
  TAX_RECEIPT_SETTINGS_CUSTOM_DONATION_RECEIPT_SAVE_ERROR = 'tax_receipt_settings:custom_donation_receipt:save_error',
  TAX_RECEIPT_SETTINGS_CUSTOM_DONATION_RECEIPT_OPEN = 'tax_receipt_settings:custom_donation_receipt:open',
  TAX_RECEIPT_SETTINGS_CUSTOM_DONATION_RECEIPT_UPLOAD = 'tax_receipt_settings:custom_donation_receipt:upload',
  TAX_RECEIPT_SETTINGS_CUSTOM_DONATION_RECEIPT_UPLOAD_ERROR = 'tax_receipt_settings:custom_donation_receipt:upload_error',
  TAX_RECEIPT_SETTINGS_CUSTOM_DONATION_RECEIPT_UPLOAD_VALIDATION_ERROR = 'tax_receipt_settings:custom_donation_receipt:upload_validation_error',

  TRANSACTION_REFUND_SUCCESSFUL = 'transaction:refund_successful',
  TRANSACTION_REFUND_ERROR = 'transaction:refund_error',

  ONBOARDING_FILL_OUT_ORGANISATION_FORM_BUTTON_CLICK = 'onboarding:fill_out_organisation_form_button_click',
  ONBOARDING_CREATE_SOLUTION_BUTTON_CLICK = 'onboarding:create_solution_button_click',

  PAYMENT_PROVIDER_CONNECT = 'payment_provider:connect',
  PAYMENT_PROVIDER_CONNECTED = 'payment_provider:connected',
  PAYMENT_PROVIDER_CONNECT_ERROR = 'payment_provider:connect_error',
  PAYMENT_PROVIDER_DISCONNECTED = 'payment_provider:disconnected',
  PAYMENT_PROVIDER_DISCONNECT_ERROR = 'payment_provider:disconnect_error',
  PAYMENT_PROVIDER_REFRESHED = 'payment_provider:refreshed',
  PAYMENT_PROVIDER_REFRESH_ERROR = 'payment_provider:refresh_error',

  ORGANISATION_SETTINGS_DOCUMENT_UPLOAD = 'organisation_settings:document_upload',
  ORGANISATION_SETTINGS_REQUEST_APPROVAL = 'organisation_settings:request_approval',
  ORGANISATION_SETTINGS_SAVE = 'organisation_settings:save',

  COMMUNICATION_SETTINGS_EMAIL_SERVICE_STATUS = 'communication_settings:email_service_status',
  COMMUNICATION_SETTINGS_EMAIL_SERVICE_TOGGLE = 'communication_settings:email_service_toggle',

  WELCOME_MODAL_CLOSE_BUTTON_CLICK = 'welcome_modal:close_button_click',

  WELCOME_DASHBOARD_CLOSE_BUTTON_CLICK = 'welcome_dashboard:close_button_click',

  THEME_TOGGLE = 'theme:toggle',

  SURVEY_SHOWN = 'survey shown',
  SURVEY_DISMISSED = 'survey dismissed',
  SURVEY_SENT = 'survey sent',
}

export type AnalyticsEventProperties = {
  [AnalyticsEventName.PAGEVIEW]: [
    {
      $pathname: string
    },
  ]
  [AnalyticsEventName.TAX_RECEIPT_SETTINGS_CUSTOM_DONATION_RECEIPT_SAVED]: [
    {
      fallback_language: string
      languages: string[]
    },
  ]
  [AnalyticsEventName.TAX_RECEIPT_SETTINGS_CUSTOM_DONATION_RECEIPT_UPLOAD]: [
    {
      language: string
    },
  ]
  [AnalyticsEventName.TAX_RECEIPT_SETTINGS_CUSTOM_DONATION_RECEIPT_UPLOAD_ERROR]: [
    {
      language: string
    },
  ]
  [AnalyticsEventName.TAX_RECEIPT_SETTINGS_CUSTOM_DONATION_RECEIPT_UPLOAD_VALIDATION_ERROR]: [
    {
      language: string
    },
  ]
  [AnalyticsEventName.EXPORT_BUTTON_CLICK]: [
    {
      export_type: ExportFormat
      export_origin: string
    },
  ]
  [AnalyticsEventName.EXPORT_SUCCESSFUL]: [
    {
      export_type: ExportFormat
      export_origin: string
      export_include_custom_fields: boolean | undefined
      export_row_count: number
    },
  ]
  [AnalyticsEventName.EXPORT_ERROR]: [
    {
      export_type: ExportFormat
      export_origin: string
      export_include_custom_fields: boolean | undefined
    },
  ]
  [AnalyticsEventName.EXPORT_EXCEEDS_LIMIT]: [
    {
      export_origin: string
      export_row_count: number
    },
  ]
  [AnalyticsEventName.EXPORT_MODAL_OPEN]: [
    {
      export_origin: string
    },
  ]
  [AnalyticsEventName.EXPORT_MODAL_CANCEL]: [
    {
      export_origin: string
    },
  ]

  [AnalyticsEventName.SOLUTION_TYPE_SELECT_CLICK]: [
    {
      solution_type: SolutionType
    },
  ]
  [AnalyticsEventName.SOLUTION_CREATED]: [
    Solution & {
      solution_config?: any
    },
  ]
  [AnalyticsEventName.SOLUTION_UPDATED]: [
    Solution & {
      solution_config?: any
    },
  ]
  [AnalyticsEventName.SOLUTION_PAYLINK_COPY_BUTTON_CLICK]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_PAYLINK_COPY_BUTTON_CLICK]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_TERMINAL_LINK_COPY_BUTTON_CLICK]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]

  [AnalyticsEventName.SOLUTION_PROMOTE_TWINT_BUTTON_OPEN]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_TWINT_EMAIL_BUTTON_COPY]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_TWINT_WEBSITE_BUTTON_COPY]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]

  [AnalyticsEventName.SOLUTION_PROMOTE_GENERIC_PAYLINK_BUTTON_OPEN]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_GENERIC_PAYLINK_WEBSITE_BUTTON_COPY]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_GENERIC_PAYLINK_EMAIL_BUTTON_COPY]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_DOWNLOAD_TWINT_QR_BUTTON_CLICK]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
      high_res: boolean
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_DOWNLOAD_TWINT_QR_SUCCESS]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
      high_res: boolean
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_DOWNLOAD_TWINT_QR_ERROR]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
      high_res: boolean
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_DOWNLOAD_UNIVERSAL_QR_BUTTON_CLICK]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_DOWNLOAD_UNIVERSAL_QR_SUCCESS]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_DOWNLOAD_UNIVERSAL_QR_ERROR]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_DOWNLOAD_TWINT_ACCEPTANCE_SIGNAGE_BUTTON_CLICK]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_DOWNLOAD_TWINT_ACCEPTANCE_SIGNAGE_SUCCESS]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_PROMOTE_DOWNLOAD_TWINT_ACCEPTANCE_SIGNAGE_ERROR]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string
    },
  ]
  [AnalyticsEventName.SOLUTION_TWINT_TERMS_ACCEPTED]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string | undefined
    },
  ]
  [AnalyticsEventName.SOLUTION_PAYMENT_PROVIDER_MODAL_CLICK]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string | undefined
    },
  ]
  [AnalyticsEventName.SOLUTION_UNSAVED_CHANGES_SAVED]: [
    {
      solution_type: SolutionType | undefined
      solution_uuid: string | undefined
    },
  ]
  [AnalyticsEventName.TRANSACTION_REFUND_SUCCESSFUL]: [
    {
      payment_uuid: string
    },
  ]
  [AnalyticsEventName.TRANSACTION_REFUND_ERROR]: [
    {
      payment_uuid: string
    },
  ]

  [AnalyticsEventName.ONBOARDING_CREATE_SOLUTION_BUTTON_CLICK]: [
    {
      status: string
    },
  ]

  [AnalyticsEventName.ORGANISATION_SETTINGS_REQUEST_APPROVAL]: [
    {
      approval_status: ApprovalStatus
    },
  ]
  [AnalyticsEventName.ORGANISATION_SETTINGS_SAVE]: [
    {
      approval_status: ApprovalStatus
    },
  ]

  [AnalyticsEventName.COMMUNICATION_SETTINGS_EMAIL_SERVICE_STATUS]: [
    {
      status: SolutionsServiceStatus
    },
  ]
  [AnalyticsEventName.COMMUNICATION_SETTINGS_EMAIL_SERVICE_TOGGLE]: [
    {
      status: SolutionsServiceStatus
    },
  ]

  [AnalyticsEventName.PAYMENT_PROVIDER_CONNECT]: [
    {
      payment_provider: PaymentProviderName
    },
  ]
  [AnalyticsEventName.PAYMENT_PROVIDER_CONNECTED]: [
    {
      payment_provider: PaymentProviderName
    },
  ]
  [AnalyticsEventName.PAYMENT_PROVIDER_CONNECT_ERROR]: [
    {
      payment_provider: PaymentProviderName
    },
  ]
  [AnalyticsEventName.PAYMENT_PROVIDER_REFRESHED]: [
    {
      payment_provider: PaymentProviderName
    },
  ]
  [AnalyticsEventName.PAYMENT_PROVIDER_REFRESH_ERROR]: [
    {
      payment_provider: PaymentProviderName
    },
  ]
  [AnalyticsEventName.PAYMENT_PROVIDER_DISCONNECTED]: [
    {
      payment_provider: PaymentProviderName
    },
  ]
  [AnalyticsEventName.PAYMENT_PROVIDER_DISCONNECT_ERROR]: [
    {
      payment_provider: PaymentProviderName
    },
  ]
  [AnalyticsEventName.THEME_TOGGLE]: [
    {
      theme: string
    },
  ]
  [AnalyticsEventName.SURVEY_SHOWN]: [
    {
      $survey_id: string
    },
  ]
  [AnalyticsEventName.SURVEY_DISMISSED]: [
    {
      $survey_id: string
    },
  ]
  [AnalyticsEventName.SURVEY_SENT]: [
    {
      $survey_id: string
      $survey_response?: string // isUserSatisfied
      $survey_response_1?: string // advancedOptions
      $survey_response_2?: string // email
      $survey_response_3?: string // organisationUuid
      $survey_response_4?: string // pricePlan
    },
  ]
}

// Inspired from https://stackoverflow.com/questions/50966229/how-can-i-conditionally-choose-types-depending-on-whether-a-property-exists-in-a
// This makes sure that if you add a new event to `AnalyticsEvent` that per default
// it will not have any properties. You have to add the properties to `AnalyticsEventProperties`
type MapAnalyticsEventsWithProperties<
  T extends string,
  Mapping extends {[key in T]?: any},
> = {
  [key in T]: Mapping extends {[P in key]: infer U} ? U : []
}

export type AnalyticsEventArgs = MapAnalyticsEventsWithProperties<
  AnalyticsEventName,
  AnalyticsEventProperties
>
