import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useInstance} from 'react-ioc'
import {DownloadOutlined} from '@ant-design/icons'
import {Button} from 'antd'
import {merge} from 'lodash-es'
import {observer} from 'mobx-react-lite'
import {
  type CellObject,
  type SheetKeys,
  utils,
  type WorkBook,
  writeFile,
} from 'xlsx'
import {autofitExportColumns} from 'lib/helpers/autofitExportColumns'
import {getDownloadFileName} from 'lib/helpers/getDownloadFileName'
import {sortExportHeaders} from 'lib/helpers/sortExportHeaders'
import {useNamespace} from 'lib/hooks/useNamespace'
import notification from 'lib/notification'
import {type TableQueryResult, type TableRequestParams} from 'models/table'
import {useTableHandler} from 'new/hooks/useTableHandler'
import {AnalyticsEventService} from 'new/services/analytics/AnalyticsEventService'
import {CurrentOrganisationStore} from 'new/stores/CurrentOrganisationStore'
import {AnalyticsEventName} from 'types/AnalyticsEventName'
import {ExportModal} from './ExportModal'

////////////////////////////////////////////////////////////////////////////////

type GenericState = {
  summary: {hits?: number | undefined}
  isFetching: boolean
}

export type FormatterRes = Record<
  string,
  CellObject | SheetKeys | boolean | string | undefined
>

export type ExportOptions = {
  exportFormat: ExportFormat
  includeCustomFields?: boolean
}

export type FormatterFn<FormatterArgs = any> = (
  arg: FormatterArgs,
  options: ExportOptions
) => FormatterRes

export type ExportProps<
  State extends GenericState,
  FetchResult extends Promise<TableQueryResult>,
> = {
  state: State
  fetch: <T extends TableRequestParams>(t: T) => FetchResult
  prepareChunkData: PrepareChunkDataFn<(t: any) => FetchResult>
}

export type PreparedDataValues = {
  isFeeCovered?: boolean
  amount?: number
  payoutAmount?: number
  payoutCurrency?: string
  fee?: number
  dateOfBirth?: '' | number | Date
  touchpoint_solution?: string
  touchpoint_solution_uuid?: string
  touchpoint_solution_type?: string
  coveredFee: number
  currency?: string
  convertedCurrency?: string
  convertedAmount?: string
  reconciliation_transaction_uuid?: string
  reference_number?: string
  end_to_end_id?: string
  solution_name?: string
  solution_uuid?: string
  solution_type?: string
  platformFee?: string
  paymentProviderFee?: number
  email_permission?: string
}

export enum ExportFormat {
  CSV = 'csv',
  XLSX = 'xlsx',
}

type GetChunkDataFn<
  Params extends TableRequestParams,
  T extends Promise<TableQueryResult>,
> = (
  chunkSize: number,
  chunkIteration: number,
  options?: Partial<Params>
) => Promise<Awaited<T>['hits']>

export type PrepareChunkDataFn<
  FetcherFn extends (params: TableRequestParams) => Promise<TableQueryResult>,
> = (
  chunkI: number,
  chunkSize: number,
  getChunkData: GetChunkDataFn<Parameters<FetcherFn>[0], ReturnType<FetcherFn>>,
  options: ExportOptions
) => Promise<object[]>

////////////////////////////////////////////////////////////////////////////////

/**
 * The maximum response size of EPMS is 10mb.
 * In 24.01.2024 the average row size is rougly 7kb. We add 3kb for safety.
 *
 * Therefore the maximum amount of rows per chunk is calculated as follows:
 *
 * EPMS_LIMIT_IN_KB = 10_000
 * APPROX_ROW_SIZE_IN_KB = 10
 * MAX_DATA_PER_CHUNK = EPMS_LIMIT_IN_KB / APPROX_ROW_SIZE_IN_KB
 */
const MAX_DATA_PER_CHUNK = 1000
export const MAX_RESULTS = 10_000 // limitation of ElasticSearch

////////////////////////////////////////////////////////////////////////////////

const Export = observer(
  <State extends GenericState, FetchResult extends Promise<TableQueryResult>>(
    props: ExportProps<State, FetchResult>
  ) => {
    const {ns} = useNamespace()
    const {t} = useTranslation(ns)
    const {tableParams} = useTableHandler()
    const {state, fetch, prepareChunkData} = props

    const {organisationUuid, data: organisation} = useInstance(
      CurrentOrganisationStore
    )
    const [isExporting, setIsExporting] = useState(false)
    const [isModalOpened, setIsModalOpened] = useState(false)

    const analyticsEventService = useInstance(AnalyticsEventService)

    const exceedsExportLimit = (): boolean => {
      const count = state.summary.hits!

      if (count > MAX_RESULTS) {
        const notificationPrefix = 'notifications.export_general_limit'
        notification.error({
          message: t(`${notificationPrefix}.message`),
          description: (
            <span
              dangerouslySetInnerHTML={{
                __html: t(`${notificationPrefix}.description_html`),
              }}
            />
          ),
          duration: 5,
        })
        analyticsEventService.emit(AnalyticsEventName.EXPORT_EXCEEDS_LIMIT, {
          export_origin: ns,
          export_row_count: count,
        })
        return true
      }

      return false
    }

    const getChunkData: GetChunkDataFn<TableRequestParams, any> = async (
      chunkSize,
      chunkIteration,
      options = {}
    ) => {
      const data = await fetch(
        merge(options, {
          organisationUuid: organisationUuid!,
          sorter: tableParams.sorter,
          filter: tableParams.filter,
          pagination: {pageSize: chunkSize, current: chunkIteration + 1},
        })
      )

      return data.hits
    }

    const getExportData = async (options: ExportOptions) => {
      const count = state.summary.hits!
      const chunkN = Math.ceil(count / MAX_DATA_PER_CHUNK)
      const chunkSize = Math.ceil(count / chunkN)

      const asyncTasks = Array<Promise<object[]>>(chunkN)

      for (let i = 0; i < chunkN; ) {
        asyncTasks[i] = prepareChunkData(i++, chunkSize, getChunkData, options)
      }

      const result = await Promise.all(asyncTasks)
      return result.flat()
    }

    const exportData = async (options: ExportOptions) => {
      const {exportFormat: type} = options
      const rows = await getExportData(options)
      const {headers, wscols} = autofitExportColumns(rows)
      const sortedHeaders = sortExportHeaders(headers)

      const sheet = utils.json_to_sheet(rows, {
        header: sortedHeaders,
      })

      sheet['!cols'] = wscols

      const workbook: WorkBook = {Sheets: {data: sheet}, SheetNames: ['data']}
      const fileName = `${getDownloadFileName(t, organisation?.name)}.${type}`

      writeFile(workbook, fileName, {
        bookType: type,
        type: 'array',
      })
      return rows
    }

    const handleExport = async (options: ExportOptions) => {
      const {
        exportFormat: export_type,
        includeCustomFields: export_include_custom_fields,
      } = options

      setIsExporting(true)

      try {
        analyticsEventService.emit(AnalyticsEventName.EXPORT_BUTTON_CLICK, {
          export_type,
          export_origin: ns,
        })
        const rows = await exportData(options)
        analyticsEventService.emit(AnalyticsEventName.EXPORT_SUCCESSFUL, {
          export_type,
          export_origin: ns,
          export_include_custom_fields,
          export_row_count: rows.length,
        })
      } catch (error) {
        analyticsEventService.emit(AnalyticsEventName.EXPORT_ERROR, {
          export_type,
          export_origin: ns,
          export_include_custom_fields,
        })
        notification.error({
          message: t('notifications.export_general_error'),
        })
      } finally {
        setIsExporting(false)
        setIsModalOpened(false)
      }
    }

    const openModal = () => {
      if (exceedsExportLimit()) {
        return
      }
      analyticsEventService.emit(AnalyticsEventName.EXPORT_MODAL_OPEN, {
        export_origin: ns,
      })
      setIsModalOpened(true)
    }

    const closeModal = () => {
      analyticsEventService.emit(AnalyticsEventName.EXPORT_MODAL_CANCEL, {
        export_origin: ns,
      })
      setIsModalOpened(false)
    }

    return (
      <>
        <ExportModal
          disabled={isExporting}
          isOpened={isModalOpened}
          close={() => closeModal()}
          onExport={values => void handleExport(values)}
        />
        <Button
          data-testid={`export.menu.button`}
          icon={<DownloadOutlined />}
          onClick={() => openModal()}
          disabled={!state.summary.hits || state.summary.hits === 0}
        >
          {t('controls.export.title')}
        </Button>
      </>
    )
  }
)

export default Export
